import './App.css';
import { BrowserRouter,  Routes, Route} from "react-router-dom" ;
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
//import 'swiper/css';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Products from './pages/Products';
import Services from './pages/Services';
import Error from './pages/Error';
import ProductsItem from './pages/ProductsItem';
import Navbar from './components/Navbar';
import ServiceList from './pages/ServiceList';
import Footers from './components/Footer';


function App() {
  
  return (
        <BrowserRouter>
      <div id="topbarid" className="container topbarcls" >
      <img id="goodstopbarid" className='goodstopbarcls'  src="goods.jpg"  alt="goodsalt" />
      <h1>Goods and Services Business</h1>
      <img id ="servicetopbarid" className='servicetopbarcls' src="services.jpg"  alt="servicalt" />
    </div>
    <Navbar />
    <Routes>
      <Route path='/' element={<Home />} />      
      <Route path='/about' element={<About />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/product' element={<Products />} />
      <Route path='/product/:item' element={<ProductsItem />} />
      <Route path='/service' element={<Services />} />
      <Route path='/service/:list' element={<ServiceList />} />
      <Route path='*' element={<Error />} />

    </Routes>
    <Footers />
    </BrowserRouter>
  );
  
}

export default App;
