import React from 'react'
import { Link, NavLink } from 'react-router-dom'

const Navbar = () => {
  return (
    <nav className='container'>
      <NavLink className='navLink' to= {"/"}>Home</NavLink>
      <NavLink className='navLink' to={"/about"}>About</NavLink>
      <NavLink className='navLink' to={"/contact"}>Contact</NavLink>
      <NavLink className='navLink' to="/product">Products</NavLink>
      <NavLink className='navLink' to={"/service"}>Services</NavLink>
  

      
    </nav>
  )
}

export default Navbar
