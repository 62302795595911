export const productData = [
{
  id : 700,
  title : "Matrix Jacket",
  srcs : "matrix_jacket.png",
  description : "Redefining the 3-in-1. The matrix system jacket changes with the seasons. Works in synergy with a lightweight and thermally robust, interchangeable thermal shell liner that zips in and out as required. An H2xtreme® waterproof/breathable outer shell with articulated elbows, to allow for a full range of movement, and fully sealed seams to ensure the jacket stays watertight. It is a fantastic jacket and can easily have a Splash logo, or one of your own, added to the front and / or back of the jacket.240gsm - 100% Polyester mechanical stretch bonded with 100% Polyester single Jersey.Machine wash cold. Do not tumble dry. Do not iron." 
  
},
{
  id : 800,
  title : "Thermal Jacket",
  srcs : "thermal_Jacket.png",
  description : "Ultra-lightweight and designed to keep the cold weather out, this polyfill jacket with showerproof D/W/R coating offers superior thermal properties, while its quilted baffles stabilize the fill to maintain consistent warmth. The jacket is beautifully sculpted for a great fit. DWR durable water repellent outer shell. Thermal shell insulated. Articulated sleeves. Brushed Tricot hand warmer pockets. Full length inner storm flap. Shell: 100% Nylon micro ripstop. Fill: 100% Polyester polyfill. Machine wash cold. Gentle cycle with like colours. Do not bleach. Hang to dry. Do not iron. Do not iron decoration. Do not dry clean." 
  
},
{
  id : 900,
  title : "Elit Thermal Jaket",
  srcs : "thermal_Jcket_Red2.png",
  description : "The ergonomically designed fitted thermal jacket is the ultimate puffer. Not content with merely providing toasty warmth with its full coverage hood and ultralight polyfill baffles, this jacket has more tricks up its sleeve: quickly and easily pack the jacket into its own pocket to magically transform it into a travel pillow. So, cosy up on a cool, crisp day and adventure forth knowing you have it all covered. Easy to add a Splash logo, or one of your own, to the front and / or your back. 40gsm - 100% nylon. Machine wash 30 degrees. Do not bleach. Do not tumble dry. Do not dry clean." 
  
},

{
  id : 1100,
  title : "Fit Tip Tshirt",
  srcs : "fit_tipped_shirt.webp",
  description : "A classic wardrobe favourite, this breton stripe t-shirt features a clean wide neckline, long sleeves and feminine fit making it an easy, versatile piece you'll come back to again and again. Add a Splash logo, or one of your own, on the front and / or the back of the tee.200gsm - 100% cotton.Machine wash at 30 degrees." 
  
},
{
  id : 1200,
  title : "Elite Swetshirt",
  srcs : "elite_swetshirt.png",
  description : "This is the top-of-the-range heavyweight sweatshirt. It has a peached finish for a luxurious, ultra-soft feel; a natty hidden pocket in the side seam for your phone; a stylish v-insert in the neck and extra long cuffs and a ribbed welt and cuffs.           330gsm - 65% cotton, 35% polyester. Machine wash at 30 degrees. Always wash dark colours separately.  " 
  
},
{
  id : 1300,
  title : "Parka Jacket",
  srcs : "5_in_1_Parka_Jacket.png",
  description : "5-in-1 H2XTREME® multilayer softshell jacket system, includes zip-out insulated liner and removable sleeves. Wearable in 5 different ways, this best-in-class jacket combines high waterproof/breathable technology for maximum comfort and protection in variable weather conditions. Choose to add a Splash logo, or one of your own, to the front and / or back of the jacket.    Shell: 182gsm. Lining: 115gsm - Shell: 100% Polyester. Lining: 100% Polyester with taffeta lining. Machine wash cold. Gentle cycle with like colours. Do not bleach. Hang to dry. Do not iron. Do not dry clean. " 
  
},
{
  id : 1400,
  title : "Baseball Tshirt",
  srcs : "baseball_tshirt.png",
  description :"This is a wonderfully stylish short-sleeve t-shirt. The plain chest area allows for the easy addition of a Splash logo, or one of your own to the front and  or back of the t-shirt. 180gsm - 100% cotton. Machine wash at 40 degrees."

}

]