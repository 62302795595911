import React from 'react'


function Footers() {
  return (
    
      <footer>
        
        <div id="footercontent">
        <img src='goods.jpg' alt="" />
          <p id="copyright_txt">Copyright &copy; by MonirRMN Digital Services. All rights reserved.</p>
          <img src='services.jpg' alt="" />
        </div>

      </footer>
      
    
  )
}

export default Footers;
