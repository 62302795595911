import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
//import 'https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css'
//  <link
//   rel="stylesheet"
//   href="https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css"
// /> 
import 'font-awesome/css/font-awesome.min.css';
//import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

//import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
//import 'swiper/css';
//import 'swiper/css/navigation';
//import 'swiper/css/pagination';
//import 'swiper/css/scrollbar';

const Home = () => {
  
  return (
 
<div class="sectioncls" onLoad={slidingfunc}>
    
    <div class="containerdiv">
       
        <div class="image-sliderfade fade">
            <img src="dres1.png" class="imgcls" alt="drsalt1"/>
            <div class="text">Thermal Jacket </div>
        </div>

        <div class="image-sliderfade fade">
            <img src="dres2.png" class="imgcls" alt="drsalt2" />
            <div class="text">Matrix Jacket</div>
        </div>

        <div class="image-sliderfade fade">
            <img src="dres3.png" class="imgcls" alt="drsalt3"/>
            <div class="text">Full Slive Tshirt</div>
        </div>

        <div class="image-sliderfade fade">
            <img src="dres4.png" class="imgcls" alt="drsalt4"/>
            <div class="text">Thermal Jacket</div>
        </div>
        <div class="image-sliderfade fade">
            <img src="dres5.png" class="imgcls" alt="drsalt5"/>
            <div class="text">Matrix Jacket</div>
        </div>

        <div class="image-sliderfade fade">
            <img src="dres6.png" class="imgcls" alt="drsalt6"/>
            <div class="text">Full Sleeve Tshirt</div>
        </div>
    </div>
    <br />

    
    <div text-align= 'center' class="dotdiv">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
        
    </div>
</div>


  )
  
  function slidingfunc(){
    
    let slideIndex = 0;
  showSlides(); 
  
  function showSlides() {
    
      let i;
  
      // get the array of divs' with classname image-sliderfade
      let slides = document.getElementsByClassName("image-sliderfade");
  
      // get the array of divs' with classname dot
      let dots = document.getElementsByClassName("dot");
  
      for (i = 0; i < slides.length; i++) {
          // initially set the display to
          // none for every image.
          slides[i].style.display = "none";
      }
  
      // increase by 1, Global variable
      slideIndex++;
  
      // check for boundary
      if (slideIndex > slides.length) {
          slideIndex = 1;
      }
  
      for (i = 0; i < dots.length; i++) {
          dots[i].className = dots[i].className.replace(" active", "");
      }
  
      slides[slideIndex - 1].style.display = "block";
      dots[slideIndex - 1].className += " active";
  
      // Change image every 2 seconds
      
      if (slideIndex<7) {
      setTimeout(showSlides, 3000);
      if(slideIndex===6){slideIndex=0}
      }  
    
    
  
    }
  } 
 
  
  
    
}

export default Home;
