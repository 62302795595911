import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Contact = () => {
  return (
    < div className='container contactcontainer'>
      <h1>Contacts</h1>
      <div id="contactCaptionId">
            <h2 class="sect_head bannerH" id="ourConatctId" >Our Contacts</h2>
            <h3 class="h3class">All Contacts are valuable for us</h3>
            </div>
      <section class="sect_centr" id="contactSectionId">
          
                   
          <div id="contactContainerDiv">
           
            <div id="contactDivId">
              <h4 class="contacth4" id="conectMeId">Connect Us</h4>
              <div id="connectDiv">
              <span><h5 class="contacth5">Call Now :   </h5> <a href="tel:+8801327380624" id="callaId">+880 1327380624</a></span>
              <span><h5 class="contacth5">Email :  </h5> <a href="mailto:monirrmn@monirrmn.01sworld.com" id="idmonirmail">monirrmn@monirrmn.01sworld.com</a></span>
              <span id="adresSpan"><h5 class="contacth5">Office : </h5><address>
                House-36(2nd Floor), <span> Road-2, Block-C , 
                 </span> <div>Banasree, Rampura, Dhaka, Bangladesh</div>
              </address></span>
            </div>            
              
            </div>
            <div id="mapDivId">
              <h4 class="contacth4" id="mapH4Id">MonirRMN Digital Services-  location map</h4>
              
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d228.2227199250128!2d90.4307839601778!3d23.76293957625842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c7bb4f1c4acf%3A0x8ee9e3eea0523dd8!2sMonirRMN%20Digital%20Services!5e0!3m2!1sen!2sbd!4v1703886825535!5m2!1sen!2sbd" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>  
            
          </div> 
          
        </section>
        <div id="webConnectionId">
            <a href="https://www.linkedin.com/in/certified-ecommerce-digital-marketing-expert-monirrmn-digital-services/" target="_blank">
            <img src='linkedin.svg' alt="linkinlogo" class="imgConectClas" /></a>
            <a href="https://www.facebook.com/ZeroOneSourcing" target="_blank"> 
            <img src='facebook.svg' alt="fblogo" class="imgConectClas"/></a>
                        <a href="https://github.com/AlifLamMonir" target="_blank"> 
              <img src='github.svg' alt="githubLogo" class="imgConectClas"/></a>
              <a href="" onclick="alert('Under development.\nThanks.')"> <img src="instagram.svg" alt="" class="imgConectClas"/></a>
            <a href="" onclick="alert('Under development.\nThanks.')"> <img src="twitter.svg" alt="" class="imgConectClas"/></a>
            <a href="" onclick="alert('Under development.\nThanks.')"> <img src="youtube.svg" alt="" class="imgConectClas"/></a>            

          </div>

        
    </div>
  )
}

export default Contact
