import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
//import 'https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css'
//  <link
//   rel="stylesheet"
//   href="https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css"
// /> 
import 'font-awesome/css/font-awesome.min.css';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const Services = () => {
  
  return (
    
    <Swiper
    
      // install Swiper modules
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      // onSwiper={(swiper) => console.log(swiper)}
      // onSlideChange={() => console.log('slide change')}
    >
      
      <SwiperSlide>          
        <article class="swiper-slide testimonial">
            <div class="testimonial__avatar">
            <img src="WoerdPresswebsite1.jpg" alt="" class="wpcls" />
            </div>
            <div class="testimonial__info">
              <h3>Wordpress Website Development</h3>
              <small>.</small>
            </div>
            <div class="testimonial__desc">
              <p>
              We build these sites for Corporate, Business, Organisations, Individuals and others
                          by fulfilling all of their requirements with latest trend at logiclal price. 
                          We build, manage and promote the sites and its contents efficiently in vibrant manner.
              </p>
            </div>
          </article>
</SwiperSlide>
      <SwiperSlide>          
        <article class="swiper-slide testimonial">
            <div class="testimonial__avatar">
              <img src="Woocommerce.jpg" alt="" class="wpcls" />
            </div>
            <div class="testimonial__info">
              <h3>E-commerce by Woocommerce</h3>
              <small>.</small>
            </div>
            <div class="testimonial__desc">
              <p>We build Ecommerce sites on WooCommerce platform by using WordPress.  We build 
              these sites in cost-effective and excellecnt customer responsive lucrative design
                            based. By using our skill to build, manage and promote of these WooCommerce 
                            sites, we make it profitable and demandable Ecommerce sites for stakeholders.  
                          
              </p>
            </div>
          </article>
</SwiperSlide>
      <SwiperSlide>          
        <article class="swiper-slide testimonial">
            <div class="testimonial__avatar">
            <img src="Shopify_Logo.png" alt="" class="wpcls" />
            </div>
            <div class="testimonial__info">
              <h3>Ecommerce by Shopify</h3>
              <small>.</small>
            </div>
            <div class="testimonial__desc">
              <p>
              We build Ecommerce sites on Shopify Platform.  These sites are very
                            demndable to store owners due to their security features and level of responses
                            from its buyers. We build, manage and promote of these sites and its products efficiently 
                            in vibrant manner to increase business of those sites with flawless transactions.  
              </p>
            </div>
          </article>
</SwiperSlide>
      <SwiperSlide>          
        <article class="swiper-slide testimonial">
            <div class="testimonial__avatar">
            <img src="HTML_CSS_JS_MongoDB.jpg" alt="" class="wpcls" />
            <img src="HTML_CSS_JS_MongoDB2.jpg" alt="" class="wpcls" />
            </div>
            <div class="testimonial__info">
              <h3>Website Development Organically</h3>
              <small>.</small>
            </div>
            <div class="testimonial__desc">
              <p>
              We build fully functional interactive websites organically by using HTML,
                            CSS and Javascript. These sites meet up all requirements of Business,
                            Corporates, Organisatios, Individulas, etc. by interacting with audiences.
                            Besdies MongoDB is used if required. We build, manage and promote the sites
                            and its contents.
              </p>
            </div>
          </article>
</SwiperSlide>
<SwiperSlide>          
        <article class="swiper-slide testimonial">
            <div class="testimonial__avatar">
            <img src="logo512.png" alt="" class="wpcls" />
            <img src="HTML_CSS_JS_MongoDB2.jpg" alt="" class="wpcls" />
            </div>
            <div class="testimonial__info">
              <h3>Website Develop on React JS</h3>
              <small>.</small>
            </div>
            <div class="testimonial__desc">
              <p>
              We make interactive websites by using React Javascript.  Our expertise in building website with latest trend blended with innovation and audience oriented eye catching  quality make our websites popular for the visitor to visit the sites repeatedly. We always try to build website with innovative ideas.We build websites to make it loading faster and look great on all devices. We always keep in touch with our customer.  
              </p>
            </div>
          </article>
</SwiperSlide>
<SwiperSlide>          
        <article class="swiper-slide testimonial">
            <div class="testimonial__avatar">
            <img src="squarespace.jpg" alt="" class="wpcls" />
            <img src="wix.jpg" alt="" class="wpcls" />
            <img src="bigcommerce.jpg" alt="" class="wpcls" />
            </div>
            <div class="testimonial__info">
              <h3>E-Commerce on Squarespace, WIX, Bigcommerce </h3>
              <small>.</small>
            </div>
            <div class="testimonial__desc">
              <p>
              We build Ecommerce website on WIX, Squarespace and BigCommerce platform.
                            With the help of our skill we build, maintain and promote those sites
                             successfully. Ecommerce sites on these platforms are popular due to their
                             own special user freifndly lucrative features. As well as the features
                             of managing the stores efficiently and profitably. 
              </p>
            </div>
          </article>
</SwiperSlide>
<SwiperSlide>          
        <article class="swiper-slide testimonial">
            <div class="testimonial__avatar">
            <img src="php-logo.png" alt="" class="wpcls" />
            </div>
            <div class="testimonial__info">
              <h3>Website Develop by PHP</h3>
              <small>.</small>
            </div>
            <div class="testimonial__desc">
              <p>
              We build websites by using PHP for Corporate, Business, Organisations, Individuals and others
                          by fulfilling all of their requirements with latest trend at logiclal price. With these websites relevant database are maintained excellently. 
                          We build, manage and promote the sites and its contents efficiently in vibrant manner.

              </p>
            </div>
          </article>
</SwiperSlide>
<SwiperSlide>          
        <article class="swiper-slide testimonial">
            <div class="testimonial__avatar">
            <img src="google_ads.jpg" alt="" class="wpcls" />
            <img src="meta_logo.jpg" alt="" class="wpcls" />
            <img src="youtube_logo.jpg" alt="" class="wpcls" />
            <img src="google_analytics.png" alt="" class="wpcls" />
            
            </div>
            <div class="testimonial__info">
              <h3>Data Driven Digital Marketing</h3>
              <small>.</small>
            </div>
            <div class="testimonial__desc">
              <p>
              We do marketing of your targeted items worldwide in internet through various media and platform. Our performance in advertisement and other activities of digital marketing for marketing in internet makes your item vibrant to targeted audience in digital worlds.  Which bring more revenue for the business.  By following proper strategy our customers become competent to compete the digital market.
              </p>
            </div>
          </article>
</SwiperSlide>
<SwiperSlide>          
        <article class="swiper-slide testimonial">
            <div class="testimonial__avatar">
            <img src="quickbooks.jpg" alt="" class="wpcls" />
                        
            </div>
            <div class="testimonial__info">
              <h3>Online Accounting Service</h3>
              <small>.</small>
            </div>
            <div class="testimonial__desc">
              <p>
              We provide QuickBooks online Accounting services to business
                            and others as per their requirements. As an expert in accounting 
                            by accademic and service, we provide perfect online Accounting services
                            on QuickBooks to our customers. We maintain proper standrd in book 
                            keeping and accounting. 
              </p>
            </div>
          </article>
</SwiperSlide>


      ...
    </Swiper>
  
  
  )
   

}

export default Services;
