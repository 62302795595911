import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { productData } from '../prodata';
import { useParams } from 'react-router-dom'


const ProductsItem = () => {
    const {item} =useParams();
    
    const [descripsondat, setdescripsion]= useState("");
    useEffect(()=>{
      const descripdat = productData.filter((descripsion)=> descripsion.title===item);
      setdescripsion(descripdat[0].description)

    },[]);

  return (
    <div className='container'>
      <h1> {item} Details</h1>
      <p>{descripsondat.slice(0, 150)}</p>
      <p>{descripsondat.slice(151, 550)}</p>
    </div>
  )
}

export default ProductsItem
