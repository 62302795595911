import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Error = () => {
  return (
    <div className='container'>
      <h1 style={{color:"orange"}}>Error</h1>
    </div>
  )
}

export default Error;
