import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

const About = () => {
  return (
    <div className='container aboutcontainer'>
      <h1>About Us</h1>
      <article>
        <p > 
        We provide varoius digital services required in various web base platfoms. It includes not only limited in design & development of various types
                    of website and development of E-Commerce site in various platforms like
                    Woocommerce, Shopify, Squarespace, Wix, etc. But also includes Search 
                    Engine Optimisation, all out social media managemnt and digital marketing. 
                    Analyse social media and setting retargeting marketing is our expertise
                    We can design logo, brochure, liflet, etc.  at custom requirement to meet 
                    up latest design trend. We can edit video for web base platform and non 
                    web base platform. We are expert in online accounting services using 
                    Quickbooks and Xero. Besides our services for data entry is highly customer
                    satisfactory and friendly.

        </p>
        <p> 
         We provide varoius digital services required in various web base platfoms. It includes not only limited in design & development of various types
                    of website and development of E-Commerce site in various platforms like
                    Woocommerce, Shopify, Squarespace, Wix, etc. But also includes Search 
                    Engine Optimisation, all out social media managemnt and digital marketing. 
                    Analyse social media and setting retargeting marketing is our expertise
                    We can design logo, brochure, liflet, etc.  at custom requirement to meet 
                    up latest design trend. We can edit video for web base platform and non 
                    web base platform. We are expert in online accounting services using 
                    Quickbooks and Xero. Besides our services for data entry is highly customer
                    satisfactory and friendly.

        </p>

      </article>
      
    </div>
  )
}

export default About
