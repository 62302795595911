import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { productData } from '../prodata';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import { Link } from 'react-router-dom';



const Products = () => {
  const [productnew, setproductnew ] = useState(productData);
  // console.log(productnew);
  const navigatepage= useNavigate();

  const truncateString =((str, num)=>{

    if(str.length > num){
      return str.slice(0, num) + "..."
    }else{
      return str;
    }
  })

  return (
    <div className='container productcontainer'>
     <h1 style={{color:"green"}} > Products </h1>

     <section>
      {productnew.map((products)=>{
        const {id, title,srcs} = products;
        return(
          <article key={id}>
            <h1>{title}</h1>
            
            <img src={srcs} style={{width:"160px",height:"180px", verticalAlign:"middle"}} />
            
            <p>{truncateString(products.description, 110)}</p>
            <Link to={title}>Click for Details</Link>
          </article>
        )

      })}

     </section>


     < button className='prodbtn' onClick={()=>{navigatepage("/")}}>Home Page</button>

    </div>
  )
}

export default Products
